import React, { useContext } from 'react';

import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import BoxColumnSection from 'components/BoxColumnSection';
import Link from 'components/Link';
import ProductsDuns from 'public/images/product_duns_registered.png';
import ProductsHovers from 'public/images/product_hoovers.png';
import ProductsReports from 'public/images/product_reports.png';
import ProductsBIR from 'public/images/products_BIR.png';
import { getExternalUrl } from 'utils/externalLinks';

const propTypes = {
  title: PropTypes.node,
  colorTitle: PropTypes.string,
};

const Products = ({ title, colorTitle }) => {
  const { t } = useTranslation('home');
  const { language } = useContext(InternationalizationContext);

  return (
    <BoxColumnSection
      id="Products"
      title={title}
      colorTitle={colorTitle}
      items={[
        {
          id: 'find_new_prospects',
          imageSrc: {
            en: ProductsHovers,
          },
          title: {
            en: t('section_7.subtitle_1'),
          },
          text: {
            en: t('section_7.text_1'),
          },
          footer: {
            en: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({ section: 'salesMarketing', language, addSource: true })}
                data-testid="product_list--new_prospects--learn_more_link_button"
              >
                {t('section_7.button')}
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
          },
          eager: true,
        },
        {
          id: 'duns_registered',
          imageSrc: {
            en: ProductsDuns,
          },
          title: {
            en: t('section_7.subtitle_2'),
          },
          text: {
            en: t('section_7.text_2'),
          },
          footer: {
            en: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'salesMarketingDuns',
                  language,
                  addSource: true,
                })}
                data-testid="product_list--duns_registered--learn_more_link_button"
              >
                {t('section_7.button')}
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
          },
          eager: true,
        },
        {
          id: 'evaluate_suppliers',
          imageSrc: {
            en: ProductsReports,
          },
          title: {
            en: t('section_7.subtitle_3'),
          },
          text: {
            en: t('section_7.text_3'),
          },
          footer: {
            en: (
              <Link
                externalLink
                data-testid="product_list--evaluate_suppliers--learn_more_link_button"
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({ section: 'thirdPartyRisk', language, addSource: true })}
              >
                {t('section_7.button')}
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
          },
          eager: true,
        },
        {
          id: 'business_information_report',
          imageSrc: {
            en: ProductsBIR,
          },
          title: {
            en: t('section_7.subtitle_4'),
          },
          text: {
            en: t('section_7.text_4'),
          },
          footer: {
            en: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'financeSolutions',
                  language,
                  addSource: true,
                })}
                data-testid="product_list--business_information--learn_more_link_button"
              >
                {t('section_7.button')}
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
          },
          eager: true,
        },
      ]}
    />
  );
};

Products.propTypes = propTypes;

export default Products;
