import React, { useContext } from 'react';

import Image from 'next/image';
import PropTypes from 'prop-types';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Column from 'components/_designSystem/_layout/Column';
import Grid from 'components/_designSystem/_layout/Grid';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './BoxColumnSection.module.scss';

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  colorTitle: PropTypes.oneOf(['black', 'white']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      footer: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
      id: PropTypes.string,
    }),
  ),
  id: PropTypes.string,
};

const BoxColumnSection = ({ title, items, colorTitle, id }) => {
  const { getTranslated } = useContext(InternationalizationContext);

  return (
    <div className={styles.container}>
      {title ? <div className={`${styles.title} ${styles[colorTitle]}`}>{title}</div> : null}
      <Grid className={`gap-0 sm:gap-8 ${styles.container}`}>
        {items.map((item) => (
          <Column col={12} sm={6} md={3} key={`${id}_${item.id}`} className={styles.column}>
            <div>
              <div className={styles.img__item}>
                <Image
                  className={styles.img__item__col}
                  src={getTranslated(item, 'imageSrc')}
                  alt="Column section"
                  priority={item.eager ? 'eager' : 'lazy'}
                  sizes="100vw"
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
              </div>
              <div className={styles.item__title}>{getTranslated(item, 'title')}</div>
              <div className={styles.item__text}>{getTranslated(item, 'text')}</div>
            </div>
            <div>{getTranslated(item, 'footer')}</div>
          </Column>
        ))}
      </Grid>
    </div>
  );
};

BoxColumnSection.propTypes = propTypes;

export default BoxColumnSection;
