import React from 'react';

import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Container from 'components/_designSystem/_layout/Container';
import Grid from 'components/_designSystem/_layout/Grid';
import styles from 'components/BigBlueButton/BigBlueButton.module.scss';
import Link from 'components/Link';

const propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  externalLink: PropTypes.bool,
};

const BigBlueButton = ({ children, href, externalLink }) => (
  <Container>
    <Grid className={styles.button}>
      <Link
        href={href}
        externalLink={externalLink}
        data-testid="big_blue_link_button"
        className={classNames(styles.list__company, 'text-white bg-blue-600 hover:bg-blue-700')}
      >
        {children}
        <FontAwesomeIcon icon={faLongArrowAltRight} />
      </Link>
    </Grid>
  </Container>
);

BigBlueButton.propTypes = propTypes;

export default BigBlueButton;
