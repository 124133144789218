import React, { useContext } from 'react';

import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import BoxColumnSection from 'components/BoxColumnSection';
import Link from 'components/Link';
import ProductsCrossReferenceIsrael from 'public/images/israel_cross_reference.png';
import ProductsLocateIsrael from 'public/images/israel_locate_potential_customers.png';
import ProductsOwnershipIsrael from 'public/images/israel_ownership_structure.png';
import ProductsReportsIsrael from 'public/images/israel_reports.png';
import { getExternalUrl } from 'utils/externalLinks';

const propTypes = {
  title: PropTypes.node,
  colorTitle: PropTypes.string,
};

const ProductListIsrael = ({ title, colorTitle }) => {
  const { language } = useContext(InternationalizationContext);

  return (
    <BoxColumnSection
      id="Products"
      title={title}
      colorTitle={colorTitle}
      items={[
        {
          id: 'find_new_prospects',
          imageSrc: {
            en: ProductsLocateIsrael,
            he: ProductsLocateIsrael,
          },
          title: {
            en: 'Cross-referencing business data',
            he: 'הצלבת נתונים עסקיים',
          },
          text: {
            en: `"Show me your friends and I'll tell you who you are" 
              is an accurate saying for the business world as well. 
              That is why we created a special report that examines 
              business ties, thus reflecting potential risks or 
              opportunities within business relationships.`,
            he: `דוח מיוחד הבוחן את מפת הקשרים האפשריים של החברה
              הנבדקת, אשר נועד לשקף את הסיכונים או ההזדמנויות
              בעבודה מולה. המידע מאפשר לזהות נקודות משותפות של
              החברה עם חברות נוספות ובהתאם לכך לצמצם או להרחיב
              את הפעילות העסקים מול קבוצת החברות.`,
          },
          footer: {
            en: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'businessPromotion',
                  language,
                  addSource: true,
                })}
                data-testid="product_list_israel--new_prospects--learn_more_link_button"
              >
                Learn more
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
            he: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'businessPromotion',
                  language,
                  addSource: true,
                })}
                data-testid="product_list_israel--new_prospects--learn_more_link_button"
              >
                להדגמה
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
          },
        },
        {
          id: 'duns_registered',
          imageSrc: {
            en: ProductsReportsIsrael,
            he: ProductsReportsIsrael,
          },
          title: {
            en: 'Locate potential customers',
            he: 'איתור לקוחות פוטנציאליים לעסק שלך',
          },
          text: {
            en: `How financially stable the business you're 
              interested in actually is. Is this business 
              connection an unnecessary risk or a golden opportunity? 
              This elaborate report was designed by our specialists, 
              based on the questioning of interested parties, 
              business environment analysis and professional 
              risk assessment.`,
            he: 'עד כמה העסק בו אתם מתעניינים מבוסס כלכלית? האם התקשרות איתו היא סיכון מיותר או הזדמנות פז?',
          },
          footer: {
            en: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'businessProtectionCreditPolicy',
                  language,
                  addSource: true,
                })}
                data-testid="product_list_israel--duns_registered--learn_more_link_button"
              >
                Learn more
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
            he: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'businessProtectionCreditPolicy',
                  language,
                  addSource: true,
                })}
                data-testid="product_list_israel--duns_registered--learn_more_link_button"
              >
                להדגמה
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
          },
        },
        {
          id: 'evaluate_suppliers',
          imageSrc: {
            en: ProductsCrossReferenceIsrael,
            he: ProductsCrossReferenceIsrael,
          },
          title: {
            en: 'Business information and credit risk management report',
            he: 'דוח מידע עסקי וניהול סיכוני אשראי',
          },
          text: {
            en: `Knowledge is power, and we have quite a lot of it when it 
              comes to demographics targeted down to the finest detail. 
              Here you can find new audiences for your business to 
              generate additional growth.`,
            he: `תרון מקיף להערכת סיכון ההתקשרות ולאיתור הזדמנויות עסקיות
              בפעילות מול חברות וארגונים עסקיים. הדוח מספק נתונים
              להערכת האיתנות הפיננסית של החברה, באמצעות בחינה כלכלית
              הכוללת תשאול בעלי ענין, ניתוח הסביבה העסקית והערכת סיכון
              ההתקשרות מולה.`,
          },
          footer: {
            en: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'businessProtectionCrossReference',
                  language,
                  addSource: true,
                })}
                data-testid="product_list_israel--evaluate_suppliers--learn_more_link_button"
              >
                Learn more
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
            he: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'businessProtectionCrossReference',
                  language,
                  addSource: true,
                })}
                data-testid="product_list_israel--evaluate_suppliers--learn_more_link_button"
              >
                להדגמה
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
          },
        },
        {
          id: 'business_information_report',
          imageSrc: {
            en: ProductsOwnershipIsrael,
            he: ProductsOwnershipIsrael,
          },
          title: {
            en: 'Ownership structure',
            he: 'מבנה בעלויות',
          },
          text: {
            en: `Ownership percentages, parent companies, subsidiaries, 
              executives... You'll find it all here. Through this 
              dynamic system, featuring information on ownership ties, 
              cross-holdings and risk rates, you can have a clearer image 
              of the company in question from a legal, business and 
              personal perspective.`,
            he: `מערכת דינמית ואינטראקטיבית, המציגה מידע לגבי קשרי
              בעלות, אחזקות צולבות ודירוג סיכון. המערכת מציגה תמונה
              מלאה של מבנה הבעלויות בחברה, כולל חברות אם, בנות וכו‘.
              המערכת מאפשרת הצלבת קשרים, אחוזי בעלויות, מידע לגבי
              מנהלים, סטטוס משפטי ועוד.`,
          },
          footer: {
            en: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'businessProtection',
                  language,
                  addSource: true,
                })}
                data-testid="product_list_israel--business_information--learn_more_link_button"
              >
                Learn more
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
            he: (
              <Link
                externalLink
                className="flex items-center text-blue-600 hover:text-gray-300"
                href={getExternalUrl({
                  section: 'businessProtection',
                  language,
                  addSource: true,
                })}
                data-testid="product_list_israel--business_information--learn_more_link_button"
              >
                להדגמה
                <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
              </Link>
            ),
          },
        },
      ]}
    />
  );
};

ProductListIsrael.propTypes = propTypes;

export default ProductListIsrael;
